@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/mixins';


.wrapper-all-content{
  background-color:#f9f9f9;
  position: relative;
  width: 100%;
}

.main-header{
  &:after{
    background: unset;
  }
}

.hp-dev{
  
.hp-box{
  display: block;
  position: relative;
  overflow: hidden;
  &:hover{
    .hp-box__image{
      transform: scale(1.07);
    }
  }

  &_3,
  &_1{
    height: 245px;
  }

  &_1{
    width: 35.937%;
    float: $right;
  }

  &_3{
    width: 64.062%;
    float: $left;
  }

  &_2,
  &_4,
  &_5,
  &_6{
    width: 50%;
    height: 450px; //320
    float: $right;
  }

  &_7{
    height: 1100px; //640
  }

  &_1,
  &_2,
  &_3,
  &_4,
  &_5,
  &_6,
  &_7{
    @include respond-to('medium'){
      width: 100%;
      float: none;
      height: 320px;
    }
  }

  // &_2{
  //   width: 100%; //690 //5.937%
  //   height: 975px;
  //   @include respond-to('medium'){
  //     height: 500px;
  //   }
  //   @include respond-to('small'){
  //     height: 300px;
  //   }
  // }

  // &_4{
  //   width: 30.35%; //370 //19.270%
  //   height: 775px;
  //   float: $right;
  //   @include respond-to('large'){
  //     width: 42.35%;
  //   }
  //   @include respond-to('small'){
  //     width: 100%;
  //     float: none;
  //     height: 400px;
  //   }
  // }

  // &_5{
  //   width: 69.65%; 
  //   height: 1095px;
  //   float: $left;
  //   @include respond-to('large'){
  //     width: 57.65%;
  //   }
  //   @include respond-to('small'){
  //     width: 100%;
  //     float: none;
  //     height: 400px;
  //   }
  // }

  // &_6{
  //   width: 30.35%;
  //   height: 320px;
  //   float: $right;
  //   @include respond-to('large'){
  //     width: 42.35%;
  //   }
  //   @include respond-to('small'){
  //     width: 100%;
  //     float: none;
  //   }
  // }

  // &_7,
  // &_8{
  //   width: 100%;
  //   height: 265px;
  // }

  &__image{
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }

  &__text{
    position: absolute;
    bottom: 0px;
    padding: 40px 60px;
    @include respond-to('small'){
      padding: 15px;
    }
    & > p{
      font-size: 30px;
      font-weight: 500;
      color: #fff;
      @include respond-to('large'){
        font-size: 22px;
        & *{
          font-size: 22px !important;
        }
      }
      @include respond-to('small'){
        font-size: 18px;
        & *{
          font-size: 18px !important;
        }
      }
    }
  }
}

.section-two-last-boxes{
  width: 50%;
  float: $left;
  @include respond-to('small'){
    width: 100%;
    float: none;
  }
}

.section-recommends{
  width: 100%;
  float: $right;
  height: 1100px; //530
  background-color: #FCCD27;
  // padding: 0 15px;
  @include respond-to('medium'){
    height: 400px;
  }
  @include respond-to('small'){
    width: 100%;
    float: none;
    // min-height: 400px;
    height: auto;
  }
  &__glob-title{
    font-size: 48px;
    color: #3a3d3f;
    text-align: center;
    margin: 50px 0;
    font-weight: 600;
    @include respond-to('large'){
      font-size: 32px;
    }
    @include respond-to('large'){
      margin-top: 0;
      padding-top: 20px;
    }
  }
  &__title{
    font-size: 26px;
    color: #3a3d3f;
    text-align: center;
    margin-bottom: 20px;
    @include respond-to('large'){
      font-size: 22px;
    }
  }
  &__content{
    font-size: 22px;
    color: #3a3d3f;
    text-align: center;
    @include respond-to('large'){
      font-size: 20px;
    }
  }
  &__stars{
    margin: 20px 0;
  }
}

#hp-slide-recommend {
  position: relative;

  .slick-slide{
    outline: none;
  }
  .slick-dots{
    bottom: 15px;
    li{
      width:11px;
      height:11px;
      margin:0;
      margin-left:9px;
      button{
        width:11px;
        height:11px;
        border-radius: 50%;
        background-color: #000;
        opacity:1;
        &:before{
          display:none;
          background-color: red;
        }
        &:hover{
          opacity:1;
          background-color: #fff;
        }
      }
      &.slick-active button{
        opacity:1;
        background-color: #fff;
      }
    }
  }
}

.recommends-item{
  width: 100%;
  height: 1100px;
  padding: 25px 15px 0;
  @include respond-to('medium'){
    height: 400px;
  }
}

.wrapper-hp-boxes-right{
  width: 64.062%;
  float: $right;
  @include respond-to('medium'){
    width: 100%;
    float: none;
  }
}

.wrapper-hp-boxes-left{
  width: 35.937%;
  float: $left;
  @include respond-to('medium'){
    width: 100%;
    float: none;
  }
}

.wrapper-hp-about{
  width: 100%;
  min-height: 360px;
  background-color: #3a3d3f;
  padding: 10px 0;
  h2{
    margin-top: 10px;
  }
  @include respond-to('huge'){
    padding: 50px 15px 30px;
  }
  @include respond-to('huge'){
    padding: 30px 15px;
  }
}

.hp-about{
  
  &_section-limit{
    max-width: 1380px;
    width: 100%;
    margin: 0 auto;
  }
  &__title{
    font-size: 35px;
    font-weight: 300;
    color: #fff;
    line-height: 1;
    margin-bottom: 30px;
  }
  &__content{
    @include respond-to('medium'){
      text-align: justify;
    }
    & > p, & a{
      font-size: 17px;
      font-weight: 300;
      color: #fff;
    }
  }
  &__logo{
    float: $left;
    margin-top: 80px;
    @include respond-to('medium'){
      float: none;
      text-align: center;
    }
  }
}
}

.dev{
  .ftr-wrap_section-limit{
    max-width: 1380px;
  }
}

.wrapper-banners{
  margin-top: 0px;  
  @include respond-to('large'){
    margin-top: 0px;
  }
  @include respond-to('medium'){
    margin-top: 0px;
  }
}